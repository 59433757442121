(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("prop-types"), require("lodash"), require("react-dom"), require("santa-components"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "prop-types", "lodash", "reactDOM", "santa-components"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("prop-types"), require("lodash"), require("react-dom"), require("santa-components")) : factory(root["React"], root["prop-types"], root["_"], root["ReactDOM"], root["santaComponents"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__15__, __WEBPACK_EXTERNAL_MODULE__25__) {
return 